import axios from 'axios'
import store from '../store/index'


let api = axios.create({
  baseURL: process.env.NODE_ENV==='production' ? 'https://foudroyant.pro:5000' : '//localhost:5001'
  // baseURL: 'https://foudroyant.pro:5000' 
  // baseURL: process.env.NODE_ENV==='production' ? '//khager.space:5000' : '//localhost:5000'
})

api.interceptors.request.use((request) => {
  updateLoaderTo(true)
  return request
})

api.interceptors.response.use((response) => {
  updateLoaderTo(false)
  return response
},
(error) => {
  updateLoaderTo(false)
  let errormessage = error.response && error.response.data.errors && error.response.data.errors.Error
  ? error.response.data.errors.Error
  : error.message
  console.log(errormessage)
})

const updateLoaderTo = (loading) => {
  store.commit('updateLoading', loading )
}

export default api

