/* eslint-disable */
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    { path: '/', component: () => import('../pages/HomePage.vue'), 
      children: [ 
        { path: '/catalog', component: () => import('../pages/CatalogPage.vue') },
        { path: '/catalog/crosscodes', component: () => import('../pages/CrossCodesPage.vue') },
        { path: '/about', component: () => import('../pages/AboutPage.vue') },
        { path: '/contacts', component: () => import('../pages/ContactPage.vue') },
        { path: '/catalog/:code', component: () => import('../pages/ProductPage.vue') },
        { path: '/', component: () => import('../pages/StartPage.vue') },
      ]
    },
    // otherwise redirect to home
    { path: '*', redirect: '/' }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
