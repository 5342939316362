<template>
  <v-app id="inspire">
    
    <router-view></router-view>
    
  </v-app>
</template>


<script>
export default {
  name: 'App',
}
</script>


<style lang="scss">
@import "./scss/variables.scss";

v-app {
  font-family: $body-font-family !important;
}

</style>
