import Vue from 'vue'
import Vuetify from 'vuetify/lib'
// import Vuetify from 'vuetify/lib/framework'
import colors from 'vuetify/lib/util/colors'
import tgIcon from '../components/TelegramIcon.vue'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.indigo.darken4,
        secondary: colors.indigo.lighten1,
        accent: colors.indigo.base,
        headers: colors.indigo.lighten5,
      },
    },
  },
  icons: {
    values: {
      telegram: { // name of our custom icon
        component: tgIcon, // our custom component
      },
    },
  },
})
