import Vue from 'vue'
import Vuex from 'vuex'
import api from '../services/api'


Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    parts: [],
    crossCars: {},
    crossCodes: [],
    cars: [],
    categories: [],
    lastReqTime: 0, // milliseconds: new Date().now()
    loading: false,
    lang: 'en',
    onePart: {},
    catalogCurrPage: 0,
    currentImg: '',
    categoryFilter: '',
    modelFilter: '',
    articleFilter: '',
    crossCodesArr: [],
    selectedCode: '',  // selected code of part from cross codes
    selectedMaker: '', // selected maker of part from cross codes
  },

  actions: {
    getCarModels({commit}) {
      // console.log(process.env.NODE_ENV)
      api.get('/cars')
      .then(response => {
        commit('setCarModels', response.data)
      })
      .catch(error => {
        console.log(error)
      })
    },
    getCategories({commit}) {
      api.get('/categories').then(response => {
        commit('setCategories', response.data)
      })
    },
    getParts({ commit }) {
      api.get('/parts').then(response => {
        // let parts = response.data
        commit('setParts', response.data['values'])
        commit('setLastReqTime', Date.now())
      })
    },
    toggleLoading(commit, loading) {
      commit('updateLoading', loading)
    },
    toggleLang({ state, commit }) {
      let lang = state.lang==='en' ? 'ru' : 'en'
      commit('setLang', lang)
    },
    getOnePart({ commit }, payload) {
      commit('updateLoading', true)
      // console.log('trying get data with part code:', payload.code)
      api.get('/parts/' + encodeURI(payload.code)).then(response => {
        let listFiles = response.data.filesList,
            firstFile = listFiles.length ? listFiles[0] : ''

        commit('setOnePart', response.data)
        commit('setCurrentImg', firstFile)
        commit('updateLoading', false)
      })
    }
  },

  mutations: {
    setSelectedMaker(state, payload) {
      state.selectedMaker = payload
    },
    setSelectedCode(state, payload) {
      state.selectedCode = payload
    },
    setArticleFilter(state, payload) {
      state.articleFilter = payload
    },
    setModelFilter(state, payload) {
      state.modelFilter = payload
    },
    setPartsByArticle(state, payload) {
      state.crossCodes = payload
    },
    setPartsByModel(state, payload) {
      state.crossCars = payload
    },
    setCurrentImg(state, payload) {
      state.currentImg = payload
    },
    setCategoryFilter(state, payload) {
      state.categoryFilter = payload
    },
    setCarModels(state, arr) {
      state.cars = arr
    },
    setCategories(state, arr) {
      state.categories = arr
    },
    setCatalogCurrentPage(state, numPage) {
      state.catalogCurrPage = numPage
    },
    clearOnePart(state) {
      state.onePart = {}
    },
    setOnePart(state, payload) {
      state.onePart = payload
    },
    setLang(state, payload) {
      state.lang = payload
    },
    setLastReqTime(state, payload) {
      state.lastReqTime = payload
    },
    setParts(state, arr) {
      state.parts = arr
    },
    updateLoading(state, loading) {
      state.loading = loading
    }
  },

  getters: {
    categoriesByLang(state) {
      let categArr = state.categories,
          lang = state.lang

      return categArr.map(row => {
        return {
          text: lang==='en' ? row[0] : row[1],
          value: row[0],
        }
      })
    },

    partsByCategory(state) {
      let partsArr = state.parts,
          category = state.categoryFilter,
          resultParts = []

      if (category) {
        resultParts = partsArr.filter(row => {
          return row[4]===category  // earlier it was: 4 - en, 5 -ru
        })
      } else {
        resultParts = partsArr
      }
      return resultParts
    },

    partsByModel(state, getters) {
      let partsArr = getters.partsByCategory,
          modelFilter = state.modelFilter
      
      if (modelFilter) {
        const resAfterFilter = partsArr.filter(row => {
          if (row[1] in state.crossCars) return row
        })
        return resAfterFilter
      }
      else {
        return partsArr
      }
    },
    
    partsByArticle(state, getters) {
      let partsArr = getters.partsByModel,
          sCode = state.selectedCode
      
      if (sCode) {
        const resAfterFilter = partsArr.filter(row => {
          if (sCode.includes(row[1])) return row
        })
        return resAfterFilter
      }
      else {
        return partsArr
      }
    },

    allParts(state, getters) {
      let partsArr = getters.partsByArticle,
          lang = state.lang,
          resultArr = []
      
      partsArr.forEach((row, index) => {
        resultArr[index] = {
          brand: row[0],
          code: row[1],
          descr: lang==='en' ? row[2] : row[3]
        }
      })
      state.loading = false
      return resultArr
    },

  }

})

export default store
